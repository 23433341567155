<template>
  <f7-page class="product-page">
    <!-- <f7-navbar back-link="Back"> </f7-navbar> -->
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_NEW')" type="back" :search="false" :cart="false" />
    </template>
      <div
            class="banner-image-c"
            @click="openLink(banner[0].Link)"
            :style="{
            'background-image': 'url(' + $h.getImage(banner[0]?.Image, 'BANNER') + ')',
            }"
    ></div>
  
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent} from "vue";
import { useStore } from "@/store";
import { get, post } from "@/utils/axios";
const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProductNavigationComponent.vue"));

export default defineComponent({
  name: "RedirectYouzanPage",
  components: {
      ProductNavigationComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore();
    const banner = ref([]);

    const openLink = async(key) => {
     let generatedShareLink = await post('/share/generate/link', {
        type: "NEWUSER",
        key: '1',
        TypeId: banner.value[0].BannerId,
      });
      if(!generatedShareLink?.ShareLink){
        throw new Error('Server not responding, Please try again later.');
        return;
      }
      window.location.href = generatedShareLink.ShareLink + '?IsQr=1';
    }

    const getData = async() => {
       let res = await  get(
          `/banner/list?page=1&size=1&LanguageCode=EN&Type=NEWUSERNOTICE`
        );
        if (res?.data) {
          banner.value = res.data
        }
    }

    onMounted(async () => {
      getData();
    });
    return {
        openLink,
        banner
    };
  },
});
</script>

<style scoped>
.container > h1 {
  text-align: center;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
p {
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.container > p > img {
  width: 100px;
}
.banner-image-c{
    background-size:cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
</style>
